import Image from "next/image";
import React from "react";
import Logo from "../../Assets/images/plaved-landscape-logo.png";

import { useRouter } from "next/router";
import { BsDoorOpenFill } from "react-icons/bs";
import { VscLoading } from "react-icons/vsc";
import { Button } from "../../Components/Common/Button";

export const LoginForm = (props: any) => {
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);

  const router = useRouter();

  const handleSignIn = () => {
    return router.push("/api/auth/login");
  };

  return (
    <div className="flex h-screen items-center justify-center shadow-lg">
      <div className="flex h-[80vh] w-11/12 flex-col items-center justify-center rounded-lg bg-white p-4 px-4 md:w-3/4 lg:w-1/3 ">
        <div className="flex h-full flex-col items-center justify-center gap-4">
          <div className="flex w-full flex-col">
            <div className="flex h-[20%] flex-shrink-0 items-center justify-center ">
              <Image
                src={Logo}
                width="0"
                height="0"
                sizes="100vw"
                className="sm:w-[60%] md:w-[50%]"
                alt="PLAVED logo"
              />
            </div>
          </div>
          {isAuthenticating ? (
            <div className="mt-2 w-[80%]">
              <form
                onSubmit={(e) => {
                  return e.preventDefault();
                }}
                className="space-y-1"
              >
                <div className="flex flex-col items-center justify-center">
                  <div className="pt-6">
                    <VscLoading className="h-12 w-12 animate-spin" />
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="flex w-full flex-col gap-4 sm:w-11/12 md:w-1/2 lg:w-4/5 xl:w-3/5">
              <Button
                onClick={() => {
                  setIsAuthenticating(!isAuthenticating);
                  handleSignIn();
                }}
                primary
                xl
                full
                className="h-18 flex flex-row justify-between"
              >
                Ir al inicio de sesión de PLAVED{" "}
                <BsDoorOpenFill className="h-6 w-6" />
              </Button>

              <div className="mt-4 w-full text-center ">
                <p className="text-sm text-gray-500">
                  {" "}
                  Para poder acceder a la plataforma, debes tener una cuenta de
                  PLAVED activa.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full flex-col justify-between px-2 text-[#748A96]">
          <p className="cursor-pointer text-center text-xs hover:underline">
            Política de Privacidad
          </p>
          <p className="text-center text-xs">
            @ 2024 - Plaved Tech SL - Todos los derechos reservados
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

/**
 * 
 * 
                  <div className="mt-2 relative">
                    <div className="absolute mt-2 inset-0 flex items-center">
                      <div className="w-full border-t border-gray-600" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-6 mt-2 bg-white text-plaved-3-600">
                        O
                      </span>
                    </div>
                  </div>
                  <div className="flex mt-4 items-center justify-between border border-sky-500">

                  <Button
                      className="w-full"
                      secondary
                      onClick={() => router.push("/register")}
                    >
                      Crear cuenta  
                                        </Button>
                   
                  </div>
 */

/**
                   *   <Button
                      className="w-full"
                      primary
                      onClick={() => signIn("auth0")}
                    >
                      Iniciar sesión
                    </Button>
                   */
